import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ h = 150, w = 150, t = 'Grid' }) => {
  return <Loader type={t} color="#d3533f" height={h} width={w} timeout={0} />;
};
Loading.propTypes = {
  h: PropTypes.number,
  w: PropTypes.number,
  t: PropTypes.string,
};
export default Loading;

// Audio
// Ball-Triangle
// Bars
// Circles
// Grid
// Hearts
// Oval
// Puff
// Rings
// TailSpin
// ThreeDots

// visible
// type
// height
// width
// color
// secondaryColor
// timeout
// radius

import React, { useState, useCallback, useEffect } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Loading from './components/ui/Loading';
import './scss/index.scss';

const App = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    let mounted = true;
    const fetchPhotos = async () => {
      try {
        const result = await axios.get(`/api/verify?token=${token}`);
        setLoading(false);
        setPhotos(result.data);
      } catch (err) {
        console.error('Frontend catch error: ', err.message);
        window.location = 'https://aeg-membership.lava.ai/S/posbFnv';
      }
    };
    if (mounted) {
      fetchPhotos();
    }
    return () => (mounted = false);
  }, [token]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="gallery-wrap" id="galleryPage">
      {loading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : (
        <>
          <Gallery photos={photos} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      )}
    </div>
  );
};

export default App;
